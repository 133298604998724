'use client';
import Link from 'next/link';
import { menu } from '@/data/menu';
import { usePathname } from 'next/navigation';
import ThemeButton from '../Buttons/ThemeButton';
import { useState, useEffect } from 'react';
import { WEBSITE_NAME } from '@/data/site';
import Scrubber from '../Svg/Scrubber';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';

export default function Navbar() {
  let pathname = usePathname() || '/';

  const [sticky, setSticky] = useState(false);
  const [navbar, setNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 125);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <nav className={`${sticky ? 'stickyNav' : ''} navbar font-poppins`}>
      <div className="w-full bg-lightTeal dark:bg-darkNavy dark:shadow-lg shadow-darkGrey">
        <div className="max-w-7xl mx-auto">
          <div className="px-2 py-5 flex items-center space-x-2 font-bold md:px-10">
            <div className="flex items-center basis-1/2 ">
              <Link href="/">
                <div className="flex justify-center items-center">
                  <div className="mr-2">
                    <Scrubber />
                  </div>

                  <h1 className="test-md sm:text-xl">{WEBSITE_NAME}</h1>
                </div>
              </Link>
            </div>
            <div className="basis-1/2">
              <ul className="flex justify-end items-center">
                {menu.map((item, i) => (
                  <li
                    className="hidden md:flex capitalize pl-8 ml-0 content__item text-lg"
                    key={i}
                  >
                    <Link
                      href={item.url}
                      className={`${
                        pathname === item.url
                          ? 'text-myWhite dark:text-darkMOrange'
                          : 'text-darkNavy hover:text-myWhite dark:text-darkMGrey dark:hover:text-darkMOrange link link--elara'
                      }`}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
                <li className="hidden md:block capitalize pl-8 ml-0 z-[999999]">
                  <ThemeButton />
                </li>
                <li className="capitalize pl-8 ml-0 flex md:hidden">
                  <button
                    className="inline-flex items-center justify-center"
                    onClick={() => setNavbar(!navbar)}
                  >
                    {navbar ? (
                      <XMarkIcon
                        className="size-10 text-black dark:text-myWhite hover:text-myWhite   dark:hover:text-lightBlue"
                        title="Mobile Button"
                      />
                    ) : (
                      <Bars3Icon
                        className="size-10 text-black dark:text-myWhite hover:text-myWhite   dark:hover:text-lightBlue"
                        title="Mobile Button"
                      />
                    )}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {navbar && (
        <div className="md:hidden z-[9999] relative -translate-y-100 transition-opacity ease-in delay-500">
          <div className="bg-[#A1CCD1] border-t-2 border-[#7C9D96] dark:bg-[#526D82] dark:border-[#9DB2BF] ">
            <ul className="flex flex-col items-center h-screen">
              {menu.map((item, i) => (
                <li
                  key={i}
                  className={`${
                    pathname === item.url
                      ? 'bg-[#7C9D96] text-[#F4F2DE] dark:bg-lightBlue' // url is active
                      : 'text-black hover:text-[#f57861] dark:hover:text-[#F4F2DE]'
                  } capitalize py-3 hover:bg-[#7C9D96] dark:hover:bg-lightBlue text-3xl w-full mb-6`}
                  onClick={() => setNavbar(!navbar)}
                >
                  <Link href={item.url} className="text-center block">
                    {item.name}
                  </Link>
                </li>
              ))}
              <li className="capitalize z-[999999]">
                <ThemeButton />
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
}
