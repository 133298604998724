'use client';

import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';

const ThemeButton = () => {
  const { setTheme, resolvedTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <button
      onClick={() => setTheme(resolvedTheme === 'dark' ? 'light' : 'dark')}
      className="p-2 dark:text-darkMGrey text- darkNavy transition ease-in button-theme rounded-full dark:border-myWhite border-darkNavy hover:scale-115   duration-150 delay-150"
      aria-label={resolvedTheme}
      title={resolvedTheme}
    >
      {resolvedTheme === 'dark' ? (
        <MoonIcon className="size-8 text-black lg:dark:text-myWhite lg:size-6" />
      ) : (
        <SunIcon className="size-8 text-black lg:size-6" />
      )}
    </button>
  );
};

export default ThemeButton;
