export const bannerText = {
  title: 'Un site despre muzica!',
  description: 'Recenzii Albume | Noi si Vechi | Diverse genuri',
};

export const CookieConsentText = {
  text1:
    'Acest site folosește cookie-uri pentru a îmbunătăți experiența utilizatorului. Prin utilizarea site-ului nostru, vă exprimați acordul pentru utilizarea tuturor cookie-urilor în conformitate cu',
  textEn1:
    'This website uses cookies to improve user experience. By using our website you consent to all cookies in accordance with our',

  text2: 'Politica noastră privind cookie-urile',
  textEn2: 'Cookie Policy',
  text3: 'Accept',
  text4: 'Refuz',
};
