import { tags } from './tags';
export const WEBSITE_NAME: string = 'Vibes & Pulse';
export const WEBSITE_URL: string = 'https://www.vibesandpulse.eu';

export const META_DESCRIPTION = `Intra pe ${WEBSITE_NAME} pentru a citi despre diverse albume muzicale din zone mai putin cunoscute si ascultate`;

export const META_SEO_KEYWORDS =
  'metal, industrial, rock, darkwave, world music, neofolk, death metal, metalcore, deathcore, brutal death metal, progressive, progressive metal, art rock, ethereal, electro, ebm, pop chamber, synthpop, synthwave, grindcore, black metal, shoegaze, hardcore, blackend, stoner, doom, doom metal ';
export const META_SEO_KEYWORDS_2 = 'Lista Review-uri Albume Muzicale';
export const META_CANONICAL = '';
export const EMAIL = 'vibesandpulse@gmail.com';
export const FB_PROFILE =
  'https://www.facebook.com/profile.php?id=61554892251643';
export const X_PROFILE = 'https://twitter.com/VibesAndPulse';
