'use client';
import { useEffect, useState } from 'react';
import { hasCookie, setCookie, deleteCookie } from 'cookies-next';
import Link from 'next/link';
import { CookieConsentText } from '@/data/textsComponents';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(true);

  useEffect(() => {
    setShowConsent(hasCookie('localConsent'));
  }, []);

  const acceptCookie = () => {
    setShowConsent(true);
    setCookie('localConsent', 'true', { maxAge: 3600 * 24 * 180 });
  };

  const deleteCookie = () => {
    setShowConsent(true);
    setCookie('localConsent', 'true', { maxAge: 3600 * 24 * 180 });
  };

  if (showConsent) {
    return null;
  }

  return (
    <div className="fixed z-[99999999] inset-0 bg-slate-700 bg-opacity-70">
      <div className="fixed xs:w-full xs:left-0 xs:right-0 sm:left-0  sm:right-0 sm:w-full bottom-0 md:w-96 md:bottom-10 md:left-auto md:right-5 px-6 py-6 bg-white font-rubik rounded-xl">
        <p className="text-black text-lg text-center">
          <span>{CookieConsentText.text1} </span>
          <Link href="/legal/cookies" className="text-red-600 font-semibold">
            {CookieConsentText.text2}.{' '}
          </Link>
        </p>
        <div className="w-full flex justify-evenly items-center">
          <button
            className="bg-darkBlue mt-3 py-2 px-8 rounded text-lg text-white"
            onClick={() => acceptCookie()}
          >
            {CookieConsentText.text3}
          </button>

          <button
            className="bg-red-600 mt-3 py-2 px-8 rounded text-lg text-white"
            onClick={() => deleteCookie()}
          >
            {CookieConsentText.text4}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
