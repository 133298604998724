export const menu = [
  {
    name: 'Noutati',
    nameEn: 'news',
    url: '/news',
  },
  {
    name: 'Cronici',
    nameEn: 'Reviews',
    url: '/reviews',
  },
  {
    name: 'categorii',
    nameEn: 'categories',
    url: '/categories',
  },

  {
    name: 'despre',
    nameEn: 'about',
    url: '/about',
  },
];

export const footerLinks = {
  quick: [
    {
      name: 'albume',
      nameEn: 'albumes',
      url: '/albums',
    },
    {
      name: 'despre',
      nameEn: 'about',
      url: '/about',
    },
  ],
  legal: [
    {
      name: 'termeni si conditii',
      nameEn: 'terms and conditions',
      url: '/legal/terms-and-conditions',
    },
    {
      name: 'politica de confidențialitate',
      nameEn: 'privacy policy',
      url: '/legal/privacy',
    },
    {
      name: 'Cookies',
      nameEn: 'Cookies',
      url: '/legal/cookies',
    },
  ],
  socialMedia: [],
};
